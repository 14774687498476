.practice-container {
    padding: 40px 15% 40px;
    display: flex;
    flex-direction: column;
    gap: 60px;
    background-color: #0c59db;
}

.practice-title {
    font-size: 35px;
    font-weight: bold;
    color: white;
    display: flex;
    justify-content: center;
}

.practice-item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 6px;
}

.practice-item-title {
    font-size: 18px;
    font-weight: 500;
    color: white;
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.practice-items-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
}

.practice-item-content {
    font-size: 15px;
    color: white;
}

@media (max-width: 1200px) {
    .practice-container {
        padding: 40px 5% 40px;
    }

    .practice-items-list {
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }
}

@media (max-width: 768px) {
    .practice-items-list {
        grid-template-columns: repeat(1, 1fr);
        gap: 40px;
    }
}