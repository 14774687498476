.contact-hero {
    background-image: url('../../assets/img/contact-hero.png');
    background-repeat: no-repeat;
    background-size: 100%;
    min-width: 100%;
    min-height: 30vw;
}

.contact-form-container {
    padding: 40px 15%;
}

.contact-form-title {
    font-size: 36px;
    font-weight: bold;
}

.location {
    padding: 40px 15%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.location-title {
    font-size: 36px;
    font-weight: bold;
}

.location-container {
    display: flex;
    flex-direction: row;
    gap: 5%;
    align-items: center;
}

.location-map img {
    width: 100%;
    border-radius: 15px;
}

.location-contact {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.location-contact-title {
    font-size: 30px;
    font-weight: 700;
    color: #0c59db;
}

.location-contact-items {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.location-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    >img {
        width: 20px;
        height: 20px;
    }
}

.location-data {
    font-size: 15px;
    color: #89898a;
}

/* Contact Form styles from here*/
.contact-form {
    margin: auto;
    max-width: 50%;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-name input {
    width: 100%;
    height: 30px;
    outline: none;
    border-radius: 5px;
    border: 0.5px solid grey;
    padding-left: 30px;
}

.form-inline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2%;
    width: 100%;
}
.form-phone {
    width: 49%;

    > input {
        width: 100%;
        height: 30px;
        outline: none;
        border-radius: 5px;
        border: 0.5px solid grey;
        padding-left: 30px;
    }
}
.form-product {
    width: 49%;
    margin-right: -5.5%;

    > select {
        width: 100%;
        height: 35px;
        outline: none;
        border-radius: 5px;
        border: 0.5px solid grey;
        padding-left: 30px;
    }
}
.form-msg textarea {
    width: 98%;
    outline: none;
    border-radius: 5px;
    border: 0.5px solid grey;
    padding: 10px 3.5%;
}
.form-terms label {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.terms-text {
    color: #656565;
    font-size: 14px;

    > span {
        color: #0c59db;
        font-weight: bold;
    }
}
.form-button {
    width: max-content;
    padding: 10px 25px;
    background-color: #0c59db;
    outline: none;
    margin: auto;
    border-radius: 3px;
    border: none;
    color: white;

    > img {
        width: 20px;
        height: 20px;
    }
}


@media (max-width: 1200px) {
    .contact-form-container {
        padding: 30px 5%;
    }

    .location {
        padding: 30px 5%;
    }
    .contact-form {
        max-width: 70%;
    }
}

@media (max-width: 768px) {
    .location-container {
        flex-direction: column;
        gap: 25px;
    }
    .contact-form {
        max-width: 90%;
    }
    .form-name input {
        width: 95%;
    }
    .form-phone input {
        width: 90%;
    }
}