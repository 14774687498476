.ttz {
    padding: 50px 15% 80px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.ttz-title {
    font-size: 35px;
    font-weight: bold;
}

.ttz-title-y {
    color: #0c59db;
}

.ttz-sub {
    width: 40%;
    color: #89898a;
    font-size: 16px;
}

.ttz-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three columns */
    column-gap: 10px;
    row-gap: 40px;
}

.ttz-card-item {
    padding: 15px 15px 5px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;        /* Stretch to fill grid row */
    /* Optional: set min-height */
    /* min-height: 450px; */
}

.ttz-card-item > img {
    width: 100%;
    height: auto;       /* Fixed height for consistency */
    object-fit: cover;
}

.ttz-item-content {
    color: #89898a;
    flex-grow: 1;        /* Fill remaining space */
}

@media (max-width: 1000px) {
    .ttz-cards {
        grid-template-columns: repeat(1, 1fr); /* One column */
    }
    .ttz-sub {
        width: 100%;
    }
}
