.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 15%;
    padding-right: 15%;
    background-color: black; /* Header background color */
    color: white; /* Text color */
    position: fixed; /* Fixed position for header */
    width: 70%;
    transition: transform 0.3s ease; /* Smooth transition */
    z-index: 1000!important; /* Ensure it stays on top */
    height: 120px; /* Allow height to adjust based on content */
}

.header-space {
    height: 120px;
    background-color: black;
}

.header-logo img {
    max-width: 100%; /* Ensure logo is responsive */
    height: auto; /* Maintain aspect ratio */
    width: 80%;
}

.header-logo {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    /* justify-content: center; */
    font-size: 20px;
    font-weight: bold;
}

.header-logo-mobile {
    display: none;
}

.header-nav {
    display: flex;
    gap: 10px; /* Space between navigation items */
    /* width: 60%; */
}

.nav {
    color: white; /* Link color */
    font-size: 16px; /* Font size */
    text-decoration: none; /* Remove underline */
    position: relative;
    padding: 5px 10px; /* Padding around links */
    transition: color 0.3s, transform 0.3s; /* Transition effects */
}

.nav:hover {
    color: white; /* Change color on hover */
    /* font-weight: bolder;  */
    transform: scale(1.01); /* Scale effect on hover */
}

.nav:focus, .nav:active {
    outline: none; /* Remove outline on focus/active */
}

.nav::after {
    content: '';
    display: block;
    height: 2px; /* Underline height */
    background: transparent; /* Default background */
    transition: background 0.3s; /* Transition for underline */
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px; /* Positioning of underline */
}

.nav:hover::after {
    background: white; /* Underline color on hover */
}

.nav.selected::after {
    background: white; /* Selected underline color */
    /* font-weight: bolder;  */
}

.burger {
    display: none; /* Hide burger button by default */
    flex-direction: column; /* Stack lines */
    cursor: pointer; /* Pointer cursor */
}

.line {
    width: 30px; /* Width of burger lines */
    height: 3px; /* Height of burger lines */
    background-color: white; /* Color of burger lines */
    margin: 4px 0; /* Space between lines */
    transition: all 0.3s; /* Smooth transitions */
}

.line.active {
    background-color: white; /* Change color when active */
}

.line.active:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px); /* Rotate first line */
}

.line.active:nth-child(2) {
    opacity: 0; /* Hide second line */
}

.line.active:nth-child(3) {
    transform: rotate(-45deg) translate(9px, -11px); /* Rotate third line */
}

/* For nav show/hide according to scroll up/down from here */
.header.hidden {
    transform: translateY(-100%); /* Hide header */
}

.header.visible {
    transform: translateY(0); /* Show header */
}

.only-mobile {
    display: none;
}

/* Dropdown Styles */
.nav-dropdown {
    position: relative;
    display: flex;
    align-items: center;
}

.nav-dropdown:hover .dropdown-content {
    display: flex;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: black;
    min-width: 140px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1000;
    flex-direction: column;
    top: 100%; /* Position below the parent */
    left: 0;
    transform: translateX(-7px);
}

.dropdown-link {
    color: white;
    padding: 13px 16px;
    text-decoration: none;
    display: block;
    font-size: 16px;
    transition: background-color 0.3s;
    position: relative;
}

/* Background fill on hover for desktop dropdown links */
.dropdown-link:hover {
    background-color: #575757;
}

/* Remove underline effect in desktop view */
.nav-dropdown .dropdown-content .dropdown-link::after {
    content: none;
}

/* Ensure the dropdown appears above other elements */
.nav-dropdown {
    z-index: 1001;
}


/* Responsive Design */
@media (max-width: 1400px) {
    .nav {
        min-width: fit-content;
    }
}

@media (max-width: 1200px) {
    .header {
        padding-left: 3%;
        padding-right: 3%;
        width: 94%;
    }

    .header-logo img {
        width: 80%;
    }
    .nav-dropdown {
        position: relative;
        display: flex;
        align-items: start;
    }
    .nav {
        min-width: fit-content;
    }
}

@media (max-width: 768px) {
    .header {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 70px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .header-space {
        height: 90px;
    }

    .header-nav {
        position: absolute;
        margin-left: 0;
        top: 80px;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        padding: 10px 0;
    }

    .nav {
        font-size: 18px;
        padding: 10px;
        width: 100%;
        text-align: center;
    }

    .burger {
        display: flex;
        position: absolute;
        right: 20px;
    }

    .header-nav {
        display: none;
        flex-direction: column;
        width: 100%;
        background-color: black;
        padding: 10px 0;
    }

    .header-nav.visible {
        display: flex;
        z-index: 1;
        height: 100vh;
        width: 60vw;
        align-items: flex-start;
        position: fixed;
        right: 0;
        padding-top: 5vh;
    }

    .header-logo-mobile {
        display: flex;
    }

    .header-logo-web {
        display: none;
    }

    .header-logo img {
        width: 80%;
    }

    .burger-logo {
        position: fixed;
        right: 20px;
        bottom: -85vh;
        width: 50vw;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }

    .burger-logo > img {
        width: 50px;
        padding-bottom: 10px;
    }

    .only-mobile {
        display: flex;
    }

    /* Hide the "About Us" link in mobile view */
    .nav-dropdown > .nav {
        display: none;
    }

    /* Ensure nav-dropdown takes full width */
    .nav-dropdown {
        width: 100%;
    }

    /* Adjust the dropdown content container to take full width */
    .nav-dropdown .dropdown-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: static;
        box-shadow: none;
        background-color: transparent;
        padding: 0;
        margin: 0;
        gap: 10px; /* Added gap */
        transform: none;
    }

    .dropdown-content {
        /* padding-left: 15px; */
    }
    
    /* Adjust padding of .dropdown-link */
    .dropdown-link {
        font-size: 18px;
        padding: 10px 0; /* Adjusted padding */
        width: 100%;
        text-align: center;
        color: white;
        text-decoration: none;
        position: relative;
        display: block;
        background-color: transparent;
        box-sizing: border-box;
        padding-left: 20px;
    }
    
    /* Ensure the underline spans correctly */
    .nav-dropdown .dropdown-content .dropdown-link::after {
        content: '';
        display: block;
        height: 2px;
        background: transparent;
        transition: background 0.3s;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -5px;
    }

    .nav-dropdown .dropdown-content .dropdown-link:hover::after,
    .nav-dropdown .dropdown-content .dropdown-link.selected::after {
        background: white;
    }

    /* Remove hover background color in mobile view */
    .dropdown-link:hover {
        background-color: transparent;
    }
}
