/* src/pages/faq/styles.faq.css */

.faq {
  padding: 0px 15% 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 20vh;
}

.faq-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 0px;
}

.faq-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.faq-item {
  background-color: #f3f7fa;
  border-radius: 6px;
  padding: 15px 20px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  transition: background-color 0.3s;
}

.faq-item:hover {
  background-color: #e8f0f8;
}

.faq-question {
  font-size: 18px;
  font-weight: 500;
  color: #0c59db;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-answer {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
}

.faq-icon {
  font-size: 24px;
  font-weight: bold;
  color: #0c59db;
}

/* Hero Section Styles */
/* src/pages/faq/styles.faq.css */

/* Existing styles ... */

.faq {
  padding: 20px 15% 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 29vh;
}

/* Hero Section Styles */
.faq-hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f0f0f0; /* Light grey background */
  padding: 50px 15%; /* Adjust padding as needed */
  width: 100%;
  box-sizing: border-box;
}

.faq-hero-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Left align on desktop */
  max-width: 60%;
}

.faq-hero-title {
  font-size: 48px;
  font-weight: bold;
  margin: 0 0 20px 0;
}

.faq-hero-image {
  max-width: 30%;
  filter: drop-shadow(5px 5px 5px #b6b6b6);
}

.faq-hero-image img {
  width: 220px;
  height: auto;
}

/* Adjusted Search Bar Styles */
.faq-search {
  position: relative;
  max-width: 90%;
  /* margin-left: auto;
  margin-right: auto; */
}

.faq-search input {
  width: 100%;
  padding: 10px 40px 10px 10px; /* Right padding accommodates the 'X' button */
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.clear-button {
  position: absolute;
  right: 10px; /* Position 'X' button inside input field */
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #888;
}

.clear-button:hover {
  color: #333;
}

.faq-category {
  font-size: 24px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 20px;
  color: #333;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 10px;
}

/* Collapse/Expand All Button */
.toggle-all-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #0c59db;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.toggle-all-button:hover {
  background-color: #0949b0;
}


/* Responsive Design */

/* Tablet View (max-width: 991px) */
@media (max-width: 991px) {
  .faq-hero {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 40px 10%;
  }

  .faq-hero-content {
      max-width: 60%;
  }

  .faq-hero-title {
      font-size: 36px;
  }

  .faq-hero-image {
      max-width: 35%;
  }

  .faq-hero-image img {
      width: 100%;
  }

  .faq-search {
      max-width: 100%;
  }
}

/* Mobile View (max-width: 767px) */
@media (max-width: 767px) {
  .faq-hero {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 30px 5%;
  }

  .faq-hero-image {
      order: -1; /* Move image to the top */
      margin-bottom: 20px;
  }

  .faq-hero-content {
      align-items: center; /* Center content */
      width: 100%;
  }

  .faq-hero-title {
      font-size: 28px;
      text-align: center;
      margin-bottom: 20px;
  }

  .faq-hero-image img {
      width: 150px;
      height: auto;
  }

  .faq-search {
      max-width: 100%;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
  }

  .faq-search input {
      width: 100%;
      padding: 10px 40px 10px 10px; /* Adjust padding-right for 'X' button */
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 4px;
      text-align: left; /* Center text inside input */
      box-sizing: border-box;
  }

  .clear-button {
      right: 10px; /* Ensure 'X' button is inside the input field */
  }
}