.calculator {
    padding: 0px 15% 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .calculator-title {
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 0;
  }
  
  .calculator-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .calculator-inputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  
  .calculator-input-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .calculator-input-group label {
    font-size: 16px;
    font-weight: 500;
  }
  
  .calculator-input-group input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-width: 95%;
  }
  
  /* Styles for Sliders */

  
  .calculator-results {
    background-color: #f3f7fa;
    padding: 20px;
    border-radius: 6px;
  }
  
  .calculator-results h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .calculator-results p {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
  }
  
  /* Progress Bar Styles */
  .coverage-indicator {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .coverage-indicator label {
    font-size: 16px;
    font-weight: 500;
  }
  
  .progress-bar {
    width: 100%;
    height: 25px;
    background-color: #e0e0e0;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .progress-bar-fill {
    height: 100%;
    transition: width 0.25s;
    border-radius: 4px 0 0 4px;
  }
  
  .coverage-indicator span {
    font-size: 14px;
    color: #333;
  }

  .calculator-description {
    font-size: 16px;
    line-height: 1.6;
    color: #333;
    margin-bottom: 20px;
    /* background-color: #f0f0f0;  */
    /* padding: 10px; */
  }
  
  @media (max-width: 1200px) {
    .calculator {
      padding: 30px 5% 30px;
    }
  
    .calculator-inputs {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  /* ... existing styles ... */

/* Styles for Sliders */
.calculator-slider-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .calculator-slider-group label {
    font-size: 16px;
    font-weight: 500;
  }
  
  .calculator-slider-group input[type="range"] {
    max-width: 100%;
    padding: 0px;
    margin: 0px;
  }
  
  .slider-value {
    font-size: 16px;
    color: #333;
    text-align: right;
  }
  
  /* Styles for the Coverage Slider */
  
  .coverage-slider {
    -webkit-appearance: none;
    appearance: none;
    max-width: 100%;
    height: 25px;
    outline: none;
    border-radius: 4px;
    overflow: hidden;
    margin: 0px;
    padding: 0px;
  }
  
  /* Remove default appearance */
  .coverage-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 25px;
    background: #000;
    cursor: pointer;
  }
  
  .coverage-slider::-moz-range-thumb {
    width: 20px;
    height: 25px;
    background: #000;
    cursor: pointer;
  }
  
  .coverage-slider::-ms-thumb {
    width: 20px;
    height: 25px;
    background: #000;
    cursor: pointer;
  }
  
  .coverage-slider::-webkit-slider-runnable-track {
    height: 25px;
  }
  
  .coverage-slider::-moz-range-track {
    height: 25px;
  }
  
  .coverage-slider::-ms-track {
    height: 25px;
  }
  
  .coverage-indicator .slider-value {
    font-size: 14px;
    color: #333;
    text-align: right;
  }
  
  /* ... rest of your styles ... */
  