.tq {
    padding: 40px 15%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.tq-title {
    font-size: 35px;
    font-weight: bold;
}

.tq-title-y {
    color: #0c59db;
}

.tq-sub {
    width: 40%;
    color: #89898a;
    font-size: 16px;
}

.tq-items {
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    gap: 4%;
}

.tq-items-l {
    width: 48%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.tq-items-r {
    width: 48%;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding-left: 25px;
    border-left: 0.5px solid #89898a;
}

.tq-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tq-item-title {
    color: #0c59db;
    font-size: 18px;
    font-weight: 700;
}

.tq-item-content {
    color: #89898a;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tq-item-sub-content span {
    color: black;
    font-weight: 700;
}

@media (max-width: 1200px) {
    .tq {
        padding: 30px 5%;
    }
}

@media (max-width: 768px) {
    .tq-sub{
        width: 100%;
    }
}